import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronsLeft } from '@react-icons/all-files/fi/FiChevronsLeft';
import { FiChevronsRight } from '@react-icons/all-files/fi/FiChevronsRight';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Col, Pagination, Row, Spinner } from 'react-bootstrap';
import { PAGE_CONFIG } from '../constants/Constants';

const PaginationComponent = (props) => {
  const {
    className,
    currentPage,
    numberOfPages,
    totalCount,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    loading,
    pageSize,
    gotoPage,
  } = props;
  const [activePage, setActivePage] = useState(1);
  // page number should be shown to user out of totalPages
  const [visiblePages, setVisiblePages] = useState([]);

  /**
   * calulate visible pages to be shown in UI, if numberOfPages is less than
   * default visible pages
   * @param visiblePages
   * @param totalPages
   * @return {*}
   */
  const filterPages = (_visiblePages, totalPages) => {
    return _visiblePages.filter((page) => page <= totalPages);
  };

  /**
   * get Default visible pages
   * @param page
   * @param total
   * @return {(number|*)[]|number[]|*}
   */
  const getVisiblePages = (page, total) => {
    if (total < 5) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [page - 2, page - 1, page, page + 1, page + 2];
    }
    if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [total - 4, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5];
  };

  const getDisplayPaginator = () => {
    const startItem = currentPage * pageSize + 1;
    const endIntex = startItem - 1 + pageSize;
    const endItem = endIntex > totalCount ? totalCount : endIntex;
    if (startItem && endItem) {
      return `${startItem} - ${endItem} of ${totalCount}`;
    }
    return `Total Count: ${totalCount || 0}`;
  };

  useEffect(() => {
    setActivePage(currentPage + 1);
    const _visiblePages = getVisiblePages(currentPage + 1, numberOfPages);
    setVisiblePages(filterPages(_visiblePages, numberOfPages));
  }, [currentPage, totalCount]);

  return (
    <Row className={classNames('mt-3', className)}>
      <Col
        style={{ flexDirection: 'flex-end' }}
        className="align-items-center d-flex justify-content-between">
        <div className="font-regular d-none d-md-block">
          {getDisplayPaginator()}{' '}
          {loading ? <Spinner className="ml-2" animation="border" size="sm" /> : ''}
        </div>
        {numberOfPages > 1 && (
          <Pagination className="mb-0">
            <Pagination.Item onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <FiChevronsLeft />
            </Pagination.Item>

            <Pagination.Item onClick={previousPage} disabled={!canPreviousPage}>
              <FiChevronLeft />
            </Pagination.Item>
            {visiblePages.map((page, index, array) => {
              return (
                <Pagination.Item
                  key={`pagination_button_${page}`}
                  onClick={() => gotoPage(page - 1)}
                  active={currentPage + 1 === page}>
                  {page}
                </Pagination.Item>
              );
            })}
            <Pagination.Item onClick={nextPage} disabled={!canNextPage}>
              <FiChevronRight />
            </Pagination.Item>
            <Pagination.Item onClick={() => gotoPage(numberOfPages - 1)} disabled={!canNextPage}>
              <FiChevronsRight />
            </Pagination.Item>
          </Pagination>
        )}
      </Col>
    </Row>
  );
};

export default PaginationComponent;
