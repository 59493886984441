import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';

export default function useAppConfig() {
  const { theme, locations, containerType } = useSelector(({ config, location }) => ({
    ...config,
    ...location,
  }));
  return {
    locations,
    locationsMap: keyBy(locations, 'id'),
    theme,
    containerType,
    containerTypeMap: keyBy(containerType, 'id'),
  };
}
