/* eslint-disable max-len */
export const MOBILE_NUMBER_REGEX = '^[6789][0-9]{9}$';
export const EMAIL_REGEX = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$';
export const PINCODE_REGEX = '^[1-9][0-9]{5}$';
export const NAME_REGEX = '^[A-Za-z0-9 ]*$';
export const PAN_REGEX = '[A-Z]{5}[0-9]{4}[A-Z]{1}$';
export const GSTIN_REGEX = '\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$';
export const IFSC_REGEX = '^[ ]*[A-Za-z]{4}0[A-Z0-9a-z]{6}[ ]*$';
export const UPI_REGEX = '^\\w+@\\w+$';
export const ACCOUNTNAME_REGEX = "^[a-zA-Z -']+";
export const ACCOUNTNUMBER_REGEX = '[0-9]$';
export const NUMBER_REGEX = '/[0-9]/g';
export const TENANT_ID_REGEX = '^[A-Za-z0-9 ]{3}$';
export const shopIdPrefixRegex = '^[A-Z]{3}$';
// export const URL_REGEX = /((https|http?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const URL_REGEX = /^(https|http?):/;

export const AUTHORITY = {
  ADMIN: 'ROLE_ADMIN',
  COLLECTION_POINT_USER: 'ROLE_COLLECTION_POINT_USER',
  DISTRIBUTION_USER: 'ROLE_DISTRIBUTION_USER',
  RECYKAL_USER: 'ROLE_RECYKAL_USER',
  USER: 'ROLE_USER',
  DISTRIBUTION_HEAD: 'ROLE_DISTRIBUTION_HEAD',
};

// Authorities for user management page
export const Authorities = {
  ROLE_COLLECTION_POINT_USER: { name: 'Collection Agent', id: 'ROLE_COLLECTION_POINT_USER' },
  ROLE_DISTRIBUTION_USER: { name: 'Distribution Agent', id: 'ROLE_DISTRIBUTION_USER' },
  ROLE_ADMIN: { name: 'Admin', id: 'ROLE_ADMIN' },
  ROLE_RECYKAL_USER: { name: 'User', id: 'ROLE_RECYKAL_USER' },
  ROLE_DISTRIBUTION_HEAD: { name: 'Distribution Head', id: 'ROLE_DISTRIBUTION_HEAD' },
};

export const AuthViews = {
  RESET_PASSWORD: '/views/ResetPasswordPage/RESET_PASSWORD',
  RESET_PASSWORD_FROM_LINK: '/views/ResetPasswordPage/RESET_PASSWORD_FROM_LINK',
  RESET_PASSWORD_SUCCESS: '/views/ResetPasswordPage/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FROM_LINK_SUCCESS: '/views/ResetPasswordPage/RESET_PASSWORD_FROM_LINK_SUCCESS',
};

export const PAGE_CONFIG = {
  size: 20,
};

export const PAYMENT_MODES = {
  CASH: { name: 'Cash', id: 'CASH' },
  UPI: { name: 'UPI', id: 'UPI' },
  BANK_TRANSFER: { name: 'Bank Transfer', id: 'BANK_TRANSFER' },
};

export const PAYMENT_MODES_VALUE = Object.values(PAYMENT_MODES);
export const PAYMENT_MODES_KEY = Object.keys(PAYMENT_MODES);

export const TRANSACTIONS_TYPE = {
  PRINTED: {
    name: 'Generated',
    id: 'PRINTED',
    desc: 'Generated/Printed and are ready for the distribution.',
  },
  ISSUED_TO_DP: {
    name: 'Issued',
    id: 'ISSUED_TO_DP',
    desc: 'Issued to distribution point.',
  },
  ISSUED_TO_SHOP: {
    name: 'Distributed',
    id: 'ISSUED_TO_SHOP',
    desc: 'Distributed to shops.',
  },
  COLLECTED: {
    name: 'Collected',
    id: 'COLLECTED',
    desc: 'Collected from the consumers.',
  },
  UN_SCANNED: {
    name: 'Un-Scanned',
    id: 'UN_SCANNED',
    desc: 'Collected from consumers without scanning QR.',
  },
  EXPIRED: {
    name: 'Expired',
    id: 'EXPIRED',
    desc: 'No more valid to be scanned.',
  },
  RETURNED_UNUSED: {
    name: 'Return Unused',
    id: 'RETURNED_UNUSED',
    desc: 'QR codes that are not used.',
  },
  ISSUED_TO_AGENT: {
    name: 'Issued to Agent',
    id: 'ISSUED_TO_AGENT',
    desc: 'Assigned to distribution Agent',
  },
};

export const TXN_TYPE_VALUE = Object.values(TRANSACTIONS_TYPE);
export const TXN_TYPE_KEY = Object.keys(TRANSACTIONS_TYPE);

export const QR_LOT_STATUS = {
  PRINTED: { name: 'Generated', id: 'PRINTED' },
  ISSUED_TO_DP: { name: 'Issued', id: 'ISSUED_TO_DP' },
  ISSUED_TO_SHOP: { name: 'Distributed', id: 'ISSUED_TO_SHOP' },
  RETURNED_UNUSED: { name: 'Return Unused', id: 'RETURNED_UNUSED' },
};

export const QR_LOT_STATUS_VALUE = Object.values(QR_LOT_STATUS);
export const QR_LOT_STATUS_KEY = Object.keys(QR_LOT_STATUS);

export const TXN_STATUS = {
  SUCCESS: { name: 'Success', id: 'SUCCESS', color: 'success' },
  CANCELLED: { name: 'Cancelled', id: 'CANCELLED', color: 'danger' },
  FAILED: { name: 'Failed', id: 'FAILED', color: 'danger' },
  IN_PROGRESS: { name: 'In Progress', id: 'IN_PROGRESS', color: 'warning' },
};

export const DATE_KEYS = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const SHOP_TYPES = [
  { id: 'SHOP', name: 'Shop' },
  { id: 'HOTEL', name: 'Hotel' },
  { id: 'RESTAURANT', name: 'Restaurant' },
  { id: 'HOMESTAY', name: 'Home Stay' },
  { id: 'DISTRIBUTOR', name: 'Distributor' },
  { id: 'OTHERS', name: 'Others' },
  { id: 'UN_SCANNED', name: 'Un-Scanned' },
];

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const LOC_TYPE = {
  DISTRIBUTION_POINT: 'DISTRIBUTION_POINT',
  COLLECTION_POINT: 'COLLECTION_POINT',
  HUB: 'HUB',
  RVM: 'RVM',
  BIN: 'BIN',
};

export const collectionTypes = [
  { name: 'Collection Point', id: 'COLLECTION_POINT' },
  { name: 'Bin', id: 'BIN' },
  { name: 'RVM', id: 'RVM' },
];

export const machineStatusMap = {
  MACHINE_FULL: { name: 'Online/Working-(Full)', id: 'MACHINE_FULL' },
  RUNNING: { name: 'Online/Working', id: 'RUNNING' },
  READY_TO_PROCESS: {
    name: 'Online/Working- (Transaction in process)',
    id: 'READY_TO_PROCESS',
  },
  NOT_RESPONDING: { name: 'Offline/Shut Down', id: 'NOT_RESPONDING' },
};

export const distributionTypes = [{ name: 'Distribution Point', id: 'DISTRIBUTION_POINT' }];

export const QRCodeStatusTitle = [
  'Generated',
  'Assign to DP',
  'Assign to Shop',
  'Collected',
  'Expired',
  'Return Unused',
];

export const QRStatus = {
  PRINTED: 'Generated',
  ISSUED_TO_DP: 'Assign to DP',
  ISSUED_TO_SHOP: 'Assign to Shop',
  COLLECTED: 'Collected',
  EXPIRED: 'Expired',
  RETURNED_UNUSED: 'Return Unused',
};

export const summaryStatusIndexObj = {
  PRINTED: 0,
  ISSUED_TO_DP: 1,
  ISSUED_TO_SHOP: 2,
  COLLECTED: 3,
  EXPIRED: 4,
  RETURNED_UNUSED: 5,
};

export const ManageQRStatusTitle = [
  'Generated',
  'Issued',
  'Distributed',
  'Expired',
  'Return Unused',
];

export const manageQRCodeIndexObj = {
  PRINTED: 0,
  ISSUED_TO_DP: 1,
  ISSUED_TO_SHOP: 2,
  EXPIRED: 3,
  RETURNED_UNUSED: 4,
};

export const QR_CODENAME = 'QRCODE';
export const CONSUMER = 'CONSUMER';
export const LOT_ID = 'LOT_ID';

export const txnStatus = [
  { name: 'Success', id: 'SUCCESS' },
  { name: 'In Progress', id: 'IN_PROGRESS' },
  { name: 'Cancelled', id: 'CANCELLED' },
];

export const qrHistoryTypes = [
  { id: 'QRCODE', value: 'Search by QR code' },
  { id: 'CONSUMER', value: 'Search by Consumer' },
  { id: 'LOT_ID', value: 'Search by Lot Id' },
];

export const QR_CODE_PRICES_LIST = [
  { name: 'Rs. 1', id: 1 },
  { name: 'Rs. 2', id: 2 },
  { name: 'Rs. 3', id: 3 },
  { name: 'Rs. 4', id: 4 },
  { name: 'Rs. 5', id: 5 },
  { name: 'Rs. 6', id: 6 },
  { name: 'Rs. 7', id: 7 },
  { name: 'Rs. 8', id: 8 },
  { name: 'Rs. 9', id: 9 },
  { name: 'Rs. 10', id: 10 },
  { name: 'Rs. 11', id: 11 },
  { name: 'Rs. 12', id: 12 },
  { name: 'Rs. 13', id: 13 },
  { name: 'Rs. 14', id: 14 },
  { name: 'Rs. 15', id: 15 },
  { name: 'Rs. 16', id: 16 },
  { name: 'Rs. 17', id: 17 },
  { name: 'Rs. 18', id: 18 },
  { name: 'Rs. 19', id: 19 },
  { name: 'Rs. 20', id: 20 },
  { name: 'Rs. 50', id: 50 },
  { name: 'Rs. 100', id: 100 },
  { name: 'Rs. 200', id: 200 },
];

export const TEMPLATE_NAME_LIST = [
  {
    name: 'Template 1',
    id: 'lots.html', // kedarnath
    width: 936,
    height: 1368,
    lotPerPage: 1,
  },
  {
    name: 'Template 2',
    id: 'lots-2.html', // baali
    width: 1080,
    height: 1440,
    lotPerPage: 4,
  },
  {
    name: 'Template 3',
    id: 'lots-4.html', // recykal
    width: 936,
    height: 1368,
    lotPerPage: 1,
  },
  {
    name: 'Template 5',
    id: 'lots-5.html', // 4-dhaam
    width: 936,
    height: 1968,
    lotPerPage: 2,
  },
];

export const TEMPLATE_CONFIG_1 = {
  height: 1368,
  width: 936,
  lotPerPage: 1,
};

export const TEMPLATE_CONFIG_2 = {
  height: 1440,
  width: 1080,
  lotPerPage: 4,
};

export const TransactionStatus = [
  { name: 'Admin to Head', id: 'ADMIN_TO_HEAD' },
  { name: 'Head to Agent', id: 'HEAD_TO_AGENT' },
  { name: 'Head to Shop', id: 'HEAD_TO_SHOP' },
  { name: 'Agent to Shop', id: 'AGENT_TO_SHOP' },
];

export const KEDARNATH_TEMPLATE_SIZE = { width: 751, height: 541 };
export const BALIYATRA_TEMPLATE_SIZE = { width: 781, height: 541 };
