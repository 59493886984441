import axios from 'axios';
import { toast } from 'react-toastify';

import * as qs from 'qs';
import { FilterParamMap } from '../shared/constants';
import ApiEndpoints from '../shared/constants/ApiEndpoints';
import { transformParams } from '../shared/utils/Helper';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.common.appCode = process.env.REACT_APP_CODE;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    toast.error(error?.response?.data.title);
    throw error?.response?.data;
  }
);

axios.interceptors.request.use((request) => {
  return request;
});

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'comma' });
};

export async function changePassword(payload) {
  const endpoint = ApiEndpoints.USER.CHANGE_PASSWORD;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function fetchAccount() {
  const endpoint = ApiEndpoints.USER.ACCOUNT;
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function validateGoogelToken(payload) {
  const endpoint = ApiEndpoints.AUTH.VAILDATE_TOKEN;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function login(payload) {
  const endpoint = ApiEndpoints.AUTH.LOGIN;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function googleLogin(payload) {
  const endpoint = ApiEndpoints.AUTH.GOOGLE_LOGIN;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function resetPassword(payload) {
  const endpoint = ApiEndpoints.AUTH.REQUEST_RESET_PASSWORD;
  const response = (
    await axios.post(endpoint, payload, {
      headers: { 'Content-Type': 'text/plain' },
    })
  ).data;
  return response;
}

export async function createPassword(payload) {
  const endpoint = ApiEndpoints.AUTH.CREATE_PASSWORD;
  const response = (await axios.post(endpoint, payload, {})).data;
  return response;
}

export async function updateAccount(payload) {
  const endpoint = ApiEndpoints.USER.ACCOUNT;
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function initPostAuthApis() {
  const endpoints = [
    axios.get(ApiEndpoints.LOCATIONS.FETCH, {
      params: { size: 1000 },
    }),
    axios.get(ApiEndpoints.USERS.FETCH, { params: { size: 1000 } }),
    axios.get(ApiEndpoints.QR_CODES.CONTAINER_TYPE, { params: { size: 1000 } }),
  ];
  const response = await axios.all(endpoints);
  return response;
}

export async function fetchUsers(_params) {
  const endpoints = ApiEndpoints.USERS.FETCH;
  const params = transformParams(_params, FilterParamMap.USERS);
  return axios.get(endpoints, { params });
}

export async function doCreateUser(payload) {
  const endpoints = ApiEndpoints.USERS.CREATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function doUpdateUser(payload) {
  const endpoints = ApiEndpoints.USERS.UPDATE;
  return (await axios.put(endpoints, payload)).data;
}

export async function doMapAgentToHead(payload) {
  const endpoints = ApiEndpoints.MAP_USER.AGENT_TO_HEAD;
  return (await axios.post(endpoints, payload))?.data;
}

export async function doFetchCustomers(_params) {
  const endpoints = ApiEndpoints.CUSTOMERS.FETCH;
  const params = transformParams(_params, FilterParamMap.CUSTOMERS);
  return axios.get(endpoints, { params });
}

export async function doCreateCustomers(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.CREATE;
  return axios.post(endpoints, payload);
}

export async function doUpdateCustomers(payload) {
  const endpoints = `${ApiEndpoints.CUSTOMERS.UPDATE}/${payload.id}`;
  return axios.put(endpoints, payload);
}

export async function doDownloadCustomers(_params) {
  const endpoints = ApiEndpoints.CUSTOMERS.DOWNLOAD;
  const params = transformParams(_params, FilterParamMap.CUSTOMERS);
  return axios.get(endpoints, { params });
}

export async function fetchLocations(_params) {
  const endpoints = ApiEndpoints.LOCATIONS.FETCH;
  const params = transformParams(_params, FilterParamMap.LOCATIONS);
  return axios.get(endpoints, { params });
}

export async function doCreateLocation(payload) {
  const endpoints = ApiEndpoints.LOCATIONS.CREATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function doUpdateLocation(payload) {
  const endpoints = ApiEndpoints.LOCATIONS.UPDATE;
  return (await axios.put(endpoints, payload)).data;
}

export async function doPrintQrCodes(payload) {
  const endpoints = ApiEndpoints.QR_CODES.PRINT;
  return (await axios.put(endpoints, payload, { responseType: 'blob' })).data;
}

export async function doAssignQrCodes(payload) {
  const endpoints = ApiEndpoints.QR_CODES.ASSIGN;
  return (await axios.put(endpoints, payload)).data;
}

export async function doFetchLots(_params) {
  const endpoints = ApiEndpoints.QR_CODES.FETCH_LOTS;
  const params = transformParams(_params, FilterParamMap.QR_CODES);
  return axios.get(endpoints, { params });
}

export async function doFetchTransactions(_params) {
  const endpoints = ApiEndpoints.TRANSACTIONS.FETCH_TRANSACTIONS;
  const params = transformParams(_params, FilterParamMap.TRANSACTIONS);
  return axios.get(endpoints, { params });
}

export async function doCreateUnScannedTxn(payload) {
  const endpoints = ApiEndpoints.TRANSACTIONS.ADD_UNUSED_TXN;
  return (await axios.post(endpoints, payload)).data;
}

/**
 * DASHBOARD APIS
 */

export async function fetchDashboardStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.FETCH_STATS;
  return axios.get(endpoints, { params });
}

export async function fetchQrCodeStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.FETCH_QR_CODE_STATS;
  return axios.get(endpoints, { params });
}

/**
 * REPORT APIS
 */
export async function getShopTxnReport(_params) {
  const endpoints = ApiEndpoints.REPORT.SHOP_TXN;
  const params = transformParams(_params, FilterParamMap.REPORTS);
  return axios.get(endpoints, { params });
}

export async function getDistributionTxnReport(_params) {
  const endpoints = ApiEndpoints.REPORT.DISTRIBUTION_TXN;
  const params = transformParams(_params, FilterParamMap.REPORTS);
  return axios.get(endpoints, { params });
}

export async function getCollectionTxnReport(_params) {
  const endpoints = ApiEndpoints.REPORT.COLLECTION_TXN;
  const params = transformParams(_params, FilterParamMap.REPORTS);
  return axios.get(endpoints, { params });
}

export async function getReturnUnusedTxnReport(_params) {
  const endpoints = ApiEndpoints.REPORT.RETURN_UNUSED;
  const params = transformParams(_params, FilterParamMap.REPORTS);
  return axios.get(endpoints, { params });
}

export async function getOfflineTxnReport(_params) {
  const endpoints = ApiEndpoints.REPORT.OFFLINE_TXN;
  const params = transformParams(_params, FilterParamMap.REPORTS);
  return axios.get(endpoints, { params });
}

export async function getTenants(params) {
  const endpoints = ApiEndpoints.TENANTS.FETCH_ALL;
  return axios.get(endpoints, { params });
}

export function createTenant(payload) {
  const endpoints = ApiEndpoints.TENANTS.CREATE;
  return axios.post(endpoints, payload)?.data;
}

export async function getAuditLogs(_params) {
  const endpoints = ApiEndpoints.LOGS.AUDIT_LOGS;
  const params = transformParams(_params, FilterParamMap.LOGS);
  return axios.get(endpoints, { params });
}
